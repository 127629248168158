dl dt {
  color: #333;
  font-weight: normal;
}
dl, dt {
  background-color: #fafafa;
  padding-top: 5px;
}
dd + dt {
  border-top: 1px solid #eee;
}

.result-title {
  text-transform: capitalize;
}

.circle-badge {
  width: 150px;
  display: inline-block;
}
.circle-badge + .circle-badge {
  margin-left: 25px;
}
.circle-badge-circle {
  display: inline-block;
  width: 50%;
  height: 75px;
  line-height: 75px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.circle-badge-title {
  font-size: 1em;
  text-align: center;
  line-height: 1;
}
