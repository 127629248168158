.navbar {
  margin-left: -15px;
  margin-right: -15px;
}
.navbar-brand {
  text-transform: uppercase;
}
.brand-icon {
  padding-right: 0.2em;
}
